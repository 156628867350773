import React from "react";
import {
  Badge,
  Button,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as shippingMethodsActions from "stores/orders/shippingMethods/actions";
import * as paymentMethodsActions from "stores/orders/paymentMethods/actions";
import AsyncSelector from "./AsyncSelector";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import apiDriver from "stores/api.driver";
import { API_URL as ORDERS_API_URL } from "stores/orders/orders/epics";
import { API_URL as USERS_API_URL } from "stores/iam/users/epics";
import { API_URL as COLLECT_POINTS_API_URL } from "stores/orders/collectPoints/epics";
import { API_URL as SHIPPING_ADDRESSES_API_URL } from "stores/orders/shippingAddresses/epics";
import { API_URL as BILLING_INFOS_API_URL } from "stores/orders/billingInfos/epics";

class DuplicationOrderModal extends React.Component {
  constructor(props) {
    super(props);

    console.log(this.props.order.products.map(p => p.productId));
    this.state = {
      users: [],
      shippingMethods: [],
      paymentMethods: [],
      collectPoints: [],
      shippingAddresses: [],
      billingInfos: [],
      duplication: {
        realization: true,
        userId: this.props.order.userId,
        shippingMethodId: this.props.order.shippingMethodId,
        collectPointId: this.props.order.collectPointId,
        shippingAddressId: this.props.order.shippingAddressId,
        senderAddressId: this.props.order.senderAddressId,
        paymentMethodId: this.props.order.paymentMethodId,
        billingInfoId: this.props.order.billingInfoId,
        products: this.props.order.products.map(p => ({
          id: p.id,
          product: true,
          files: true,
          verification: true,
          notes: true,
          recalculatePrice: p.productId !== null ? true : false,
          recalculateWeight: p.productId !== null ? true : false,
          recalculateRealizationTime: p.productId !== null ? true : false,
        })),
      }
    };
  }

  toggle = () => this.props.toggle();
  parseValue = (target) => this.props.parseValue(target);

  changeDuplicationData = (e) => {
    console.log(e);
    const { name, value } = e.target;
    console.log(name, value);
    this.setState({
      duplication: {
        ...this.state.duplication,
        [name]: value,
      },
    });
  }

  onChange = (productId, property, value) => {
    const { duplication } = this.state;
    const { products } = duplication;
    const product = products.find(p => p.id === productId);
    if (!product) {
      return;
    }
    product[property] = value;
    console.log(products);
    this.setState({
      duplication: {
        ...duplication,
        products: products,
      },
    });
  };

  onToggle = (productId, value) => {
    const { duplication } = this.state;
    const { products } = duplication;
    const product = products.find(p => p.id === productId);
    if (!product) {
      return;
    }
    product.product = value;
    product.files = value;
    product.verification = value;
    product.notes = value;
    product.recalculatePrice = value;
    product.recalculateWeight = value;
    product.recalculateRealizationTime = value;
    console.log(products);
    this.setState({
      duplication: {
        ...duplication,
        products: products,
      },
    });
  }

  componentDidMount = () => {
    this.props.listShippingMethods();
    this.props.listPaymentMethods();
    this.loadShippingAddresses();
    this.loadBillingInfos();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.shippingMethods !== this.props.shippingMethods) {
      this.setState({
        shippingMethods: this.props.shippingMethods,
        shippingMethodsLoaded: true,
      });
    }
    if (prevProps.paymentMethods !== this.props.paymentMethods) {
      this.setState({
        paymentMethodsLoaded: true,
        paymentMethods: this.props.paymentMethods,
      });
    }
    if (prevState?.duplication?.userId !== this.state?.duplication?.userId) {
      this.loadShippingAddresses();
      this.loadBillingInfos();
    }
  }

  filterProducts = (inputValue) => {
    const { products } = this.state;
    return new Promise((resolve, reject) => {
      let result = products;
      if (inputValue) {
        result = result.filter((m) =>
          m.currentTranslation?.title
            .toLowerCase()
            .includes(inputValue.toLowerCase()),
        );
      }
      result = result.map(this.renderProduct);
      resolve(result);
    });
  };
  renderProduct = (method) => ({
    value: method.id,
    label: method.currentTranslation?.title,
  });

  changeProduct = (e) => {
    let { products } = this.state;
    const value = e.target.value;
    const filtered = [...products.filter((p) => p.id === value)];
    if (filtered.length === 1) {
      this.setState({ product: filtered[0] });
    } else {
      this.setState({ product: null });
    }
  };

  submit = () => {
    const { duplication } = this.state;
    const url = `${ORDERS_API_URL}${this.props.order.id}/Duplicate`;
    console.log(duplication);
    console.log(url);
    apiDriver.post(url, duplication)
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((response) => {
        console.log(response);
        this.toggle();
        this.props.history.push(`/admin/orders/orders/${response.id}`);
      });
  };

  renderCancelButton = () => (
    <Button color="light" onClick={() => this.toggle()}>
      Anuluj
    </Button>
  );

  renderSubmitButton = () => {
    return (
      <Button color="primary" onClick={() => this.submit()}>
        Duplikuj
      </Button>
    );
  };

  renderProduct = (orderProduct) => {
    const { duplication } = this.state;
    const duplicatedProduct = duplication.products.find(p => p.id === orderProduct.id);
    return (
      <div key={orderProduct.id} className="pb-3">
        <FormGroup key={orderProduct.id} className="px-5">
          <InputGroup>
            <Input
              type="checkbox"
              id={`product-${orderProduct.id}-product`}
              name={`products[${orderProduct.id}][product]`}
              value={true}
              onChange={e => this.onToggle(orderProduct.id, e.target.checked)}
              checked={duplicatedProduct?.product ?? true}
              defaultChecked
            />
            <Label for={`product-${orderProduct.id}-product`}>
              <h4 className="m-0 p-0"><Badge className="position-relative bottom-1 bg-primary text-white">{orderProduct.number}</Badge> {orderProduct.title ?? orderProduct.product?.currentTranslation?.title}</h4>
            </Label>
          </InputGroup>
          <div className="pl-3">
            <InputGroup>
              <Input
                type="checkbox"
                id={`product-${orderProduct.id}-files`}
                name={`products[${orderProduct.id}][files]`}
                value={true}
                onChange={e => this.onChange(orderProduct.id, 'files', e.target.checked)}
                checked={duplicatedProduct?.files ?? true}
              />
              <Label for={`product-${orderProduct.id}-files`}>Projekty i materiały</Label>
            </InputGroup>
            <InputGroup>
              <Input
                type="checkbox"
                id={`product-${orderProduct.id}-verification`}
                name={`products[${orderProduct.id}][verification]`}
                value={true}
                onChange={e => this.onChange(orderProduct.id, 'verification', e.target.checked)}
                checked={duplicatedProduct?.verification ?? true}
                defaultChecked
              />
              <Label for={`product-${orderProduct.id}-verification`}>Uwagi do realizacji</Label>
            </InputGroup>
            <InputGroup>
              <Input
                type="checkbox"
                id={`product-${orderProduct.id}-notes`}
                name={`products[${orderProduct.id}][notes]`}
                value={true}
                onChange={e => this.onChange(orderProduct.id, 'notes', e.target.checked)}
                checked={duplicatedProduct?.notes ?? true}
                defaultChecked
              />
              <Label for={`product-${orderProduct.id}-notes`}>Notatki</Label>
            </InputGroup>
            <InputGroup>
              <Input
                type="checkbox"
                id={`product-${orderProduct.id}-recalculatePrice`}
                name={`products[${orderProduct.id}][recalculatePrice]`}
                value={true}
                onChange={e => this.onChange(orderProduct.id, 'recalculatePrice', e.target.checked)}
                checked={duplicatedProduct?.recalculatePrice ?? true}
                defaultChecked={orderProduct.productId !== null}
                disabled={orderProduct.productId === null}
              />
              <Label for={`product-${orderProduct.id}-recalculatePrice`}>Rekalkuluj cenę</Label>
            </InputGroup>
            <InputGroup>
              <Input
                type="checkbox"
                id={`product-${orderProduct.id}-recalculateWeight`}
                name={`products[${orderProduct.id}][recalculateWeight]`}
                value={true}
                onChange={e => this.onChange(orderProduct.id, 'recalculateWeight', e.target.checked)}
                checked={duplicatedProduct?.recalculateWeight ?? true}
                defaultChecked={orderProduct.productId !== null}
                disabled={orderProduct.productId === null}
              />
              <Label for={`product-${orderProduct.id}-recalculateWeight`}>Rekalkuluj wagę</Label>
            </InputGroup>
            <InputGroup>
              <Input
                type="checkbox"
                id={`product-${orderProduct.id}-recalculateRealizationTime`}
                name={`products[${orderProduct.id}][recalculateRealizationTime]`}
                value={true}
                onChange={e => this.onChange(orderProduct.id, 'recalculateRealizationTime', e.target.checked)}
                checked={duplicatedProduct?.recalculateRealizationTime ?? true}
                defaultChecked={orderProduct.productId !== null}
                disabled={orderProduct.productId === null}
              />
              <Label for={`product-${orderProduct.id}-recalculateRealizationTime`}>Rekalkuluj czas realizacji</Label>
            </InputGroup>
          </div>
        </FormGroup>
      </div>
    );
  };

  loadUsers = async (inputValue) => {
    const result = await apiDriver
      .get(USERS_API_URL + "?Take=10&SearchText=" + inputValue)
      .toPromise();
    const users = result.response;
    return users.map((user) => ({
      value: user.id,
      label: user.username,
    }));
  };

  loadBillingInfos = () => {
    const { duplication } = this.state;
    if (!duplication || !duplication.userId) {
      return;
    }

    const params = {
      take: 1000,
      filters: {
        userId: {
          filterVal: duplication.userId,
          filterType: "Guid",
          comparator: "=",
          caseSensitive: false,
          filterKey: "userId",
        },
      },
    };
    apiDriver
      .get(BILLING_INFOS_API_URL + apiDriver.buildIndexAttributes(params))
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        this.setState({ billingInfos: data || [], billingInfosLoaded: true });
      });
  };

  loadShippingAddresses = () => {
    const { duplication } = this.state;
    if (!duplication || !duplication.userId) {
      return;
    }

    const params = {
      take: 1000,
      filters: {
        userId: {
          filterVal: duplication.userId,
          filterType: "Guid",
          comparator: "=",
          caseSensitive: false,
          filterKey: "userId",
        },
      },
    };
    apiDriver
      .get(SHIPPING_ADDRESSES_API_URL + apiDriver.buildIndexAttributes(params))
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        this.setState({
          shippingAddresses: data || [],
          shippingAddressesLoaded: true,
        });
      });
  };

  loadCollectPoints = () => {
    const { order } = this.props;
    if (!order || !order.shippingMethodId) {
      return;
    }

    const params = {
      take: 1000,
      filters: {
        methodId: {
          filterVal: order.shippingMethodId,
          filterType: "Guid",
          comparator: "=",
          caseSensitive: false,
          filterKey: "methodId",
        },
      },
    };
    apiDriver
      .get(COLLECT_POINTS_API_URL + apiDriver.buildIndexAttributes(params))
      .pipe(
        map((response) => response.response),
        catchError((error) => {
          return of(error);
        }),
      )
      .subscribe((data) => {
        this.setState({ collectPoints: data || [], collectPointsLoaded: true });
      });
  };

  renderUser = (user) => ({ value: user.id, label: user.username });

  renderUserSelector = () => {
    const { order } = this.props;
    const { users, duplication } = this.state;
    return (
      <div className="my-3">
        <h3 className="p-0 m-0 pb-3">Zamawiający</h3>
        <AsyncSelector
          title="Zamawiający"
          name="userId"
          placeholder="Szukaj..."
          allowNull={true}
          allowCreate={true}
          options={[...(order.user ? [order.user] : []), ...users].map(this.renderUser)}
          value={duplication.userId}
          onChange={this.changeDuplicationData}
          onSearch={this.loadUsers}
        />
      </div>
    );
  }

  filterShippingMethods = (inputValue) => {
    const { shippingMethods } = this.state;
    return new Promise(async (resolve, reject) => {
      let result = shippingMethods;
      if (inputValue) {
        result = result.filter((m) =>
          m.currentTranslation?.title
            .toLowerCase()
            .includes(inputValue.toLowerCase()),
        );
      }
      result = result.map((m) => this.renderPaymentMethod(m));
      resolve(result);
    });
  }

  renderShippingMethod = (method) => ({
    value: method.id,
    label: method.currentTranslation?.title,
  });

  renderShippingMethodSelector = () => {
    const { order } = this.props;
    const { shippingMethods, duplication } = this.state;
    return (
      <div className="my-3">
        <h3 className="p-0 m-0 pb-3">Metoda dostawy</h3>
        <AsyncSelector
          title="Metoda dostawy"
          name="shippingMethodId"
          placeholder="Szukaj..."
          allowNull={true}
          allowCreate={true}
          options={[...(order.shippingMethod ? [order.shippingMethod] : []), ...shippingMethods].map(this.renderShippingMethod)}
          value={duplication.shippingMethodId}
          onChange={this.changeDuplicationData}
          onSearch={this.filterShippingMethods}
        />
      </div>
    )
  }

  filterPaymentMethods = (inputValue) => {
    const { paymentMethods } = this.state;
    return new Promise(async (resolve, reject) => {
      let result = paymentMethods;
      if (inputValue) {
        result = result.filter((m) =>
          m.currentTranslation?.title
            .toLowerCase()
            .includes(inputValue.toLowerCase()),
        );
      }
      result = result.map((m) => this.renderPaymentMethod(m));
      resolve(result);
    });
  }

  renderPaymentMethod = (method) => ({
    value: method.id,
    label: method.currentTranslation?.title,
  });

  renderPaymentMethodSelector = () => {
    const { order } = this.props;
    const { paymentMethods, duplication } = this.state;
    return (
      <div className="my-3">
        <h3 className="p-0 m-0 pb-3">Metoda płatności</h3>
        <AsyncSelector
          title="Metoda płatności"
          name="paymentMethodId"
          placeholder="Szukaj..."
          allowNull={true}
          allowCreate={true}
          options={[...(order.paymentMethod ? [order.paymentMethod] : []), ...paymentMethods].map(this.renderPaymentMethod)}
          value={duplication.paymentMethodId}
          onChange={this.changeDuplicationData}
          onSearch={this.filterPaymentMethods}
        />
      </div>
    )
  }

  abstractAddressString = (address) =>
    `${address.line1}${address.line2 ? " " + address.line2 : ""}, ${address.postalCode
    } ${address.city}`;

  abstractContactString = (address) => {
    let contactString = [address.person, address.phone, address.email].filter(
      (a) => a,
    );
    return contactString.length > 0 ? "(" + contactString.join(", ") + ")" : "";
  };

  billingInfoLabel = (info) => {
    return (
      <div>
        <div>
          {info.buyerName ? info.buyerName + " - " : ""}{" "}
          {this.abstractAddressString(info)}
        </div>
        <div className="text-muted">
          <small>
            {info.vatID ? "VAT: " + info.vatID + " " : ""}
            {this.abstractContactString(info)}
          </small>
        </div>
      </div>
    );
  };

  senderAddressLabel = (address) => {
    return (
      <div>
        <div>{this.abstractAddressString(address)}</div>
        <div className="text-muted">
          <small>{this.abstractContactString(address)}</small>
        </div>
      </div>
    );
  };

  shippingAddressLabel = (address) => {
    return (
      <div>
        <div>{this.abstractAddressString(address)}</div>
        <div className="text-muted">
          <small>{this.abstractContactString(address)}</small>
        </div>
      </div>
    );
  };

  shippingAddressLabel = (address) => {
    let string = `${address.line1}${address.line2 ? " " + address.line2 : ""
      }, ${address.postalCode} ${address.city}`;
    let contactString = [address.person, address.phone, address.email].filter(
      (a) => a,
    );
    return (
      <div>
        <div>{string}</div>
        <div className="text-muted">
          <small>
            {contactString.length > 0
              ? "(" + contactString.join(", ") + ")"
              : ""}
          </small>
        </div>
      </div>
    );
  };

  renderBillingInfo = (address) => ({
    value: address.id,
    label: this.billingInfoLabel(address),
  });

  renderShippingAddress = (address) => ({
    value: address.id,
    label: this.shippingAddressLabel(address),
  });

  renderSenderAddress = (address) => ({
    value: address.id,
    label: this.senderAddressLabel(address),
  });

  filterCollectPoints = (inputValue) => {
    const { collectPoints } = this.state;
    inputValue = inputValue.toLowerCase();
    return new Promise((resolve, reject) => {
      let options = collectPoints || [];
      if (inputValue) {
        options = options.filter(
          (a) =>
            a.line1.toLowerCase().includes(inputValue) ||
            a.line2.toLowerCase().includes(inputValue) ||
            a.postalCode.toLowerCase().includes(inputValue) ||
            a.city.toLowerCase().includes(inputValue) ||
            a.title.toLowerCase().includes(inputValue) ||
            a.person.toLowerCase().includes(inputValue) ||
            a.email.toLowerCase().includes(inputValue) ||
            a.phone.toLowerCase().includes(inputValue),
        );
      }
      options = options.map((option) => this.renderShippingAddress(option));
      resolve(options);
    });
  };

  renderCollectPointSelector = () => {
    const { order } = this.props;
    const { collectPoints, duplication } = this.state;
    return (
      <div className="my-3">
        <h3 className="p-0 m-0 pb-3">Punkt odbioru</h3>
        <AsyncSelector
          title="Punkt odbioru"
          name="collectPointId"
          placeholder="Szukaj..."
          allowNull={true}
          allowCreate={true}
          options={[...(order.collectPoint ? [order.collectPoint] : []), ...collectPoints].map(this.renderShippingAddress)}
          value={duplication.collectPointId}
          onChange={this.changeDuplicationData}
          onSearch={this.filterCollectPoints}
        />
      </div>
    )
  }

  filterShippingAddresses = (inputValue) => {
    const { shippingAddresses } = this.state;
    return new Promise((resolve, reject) => {
      let options = shippingAddresses || [];
      if (inputValue) {
        options = options.filter(
          (a) =>
            a.line1.toLowerCase().includes(inputValue) ||
            a.line2.toLowerCase().includes(inputValue) ||
            a.postalCode.toLowerCase().includes(inputValue) ||
            a.city.toLowerCase().includes(inputValue) ||
            a.title.toLowerCase().includes(inputValue),
        );
      }
      options = options.map((option) => this.renderShippingAddress(option));
      resolve(options);
    });
  };

  renderShippingAddressSelector = () => {
    const { order } = this.props;
    const { shippingAddresses, duplication } = this.state;
    return (
      <div className="my-3">
        <h3 className="p-0 m-0 pb-3">Adres dostawy</h3>
        <AsyncSelector
          title="Adres dostawy"
          name="shippingAddressId"
          placeholder="Szukaj..."
          allowNull={true}
          allowCreate={true}
          options={[...(order.shippingAddress ? [order.shippingAddress] : []), ...shippingAddresses].map(this.renderShippingAddress)}
          value={duplication.shippingAddressId}
          onChange={this.changeDuplicationData}
          onSearch={this.filterShippingAddresses}
        />
      </div>
    )
  }

  renderSenderAddressSelector = () => {
    const { order } = this.props;
    const { shippingAddresses, duplication } = this.state;
    return (
      <div className="my-3">
        <h3 className="p-0 m-0 pb-3">Adres nadawcy</h3>
        <AsyncSelector
          title="Adres nadawcy"
          name="senderAddressId"
          placeholder="Szukaj..."
          allowNull={true}
          allowCreate={true}
          options={[...(order.senderAddress ? [order.senderAddress] : []), ...shippingAddresses].map(this.renderShippingAddress)}
          value={duplication.senderAddressId}
          onChange={this.changeDuplicationData}
          onSearch={this.filterShippingAddresses}
        />
      </div>
    )
  }

  filterBillingInfos = (inputValue) => {
    const { billingInfos } = this.state;
    inputValue = inputValue.toLowerCase();
    return new Promise((resolve, reject) => {
      let options = billingInfos || [];
      if (inputValue) {
        options = options.filter(
          (a) =>
            a.line1.toLowerCase().includes(inputValue) ||
            a.line2.toLowerCase().includes(inputValue) ||
            a.postalCode.toLowerCase().includes(inputValue) ||
            a.city.toLowerCase().includes(inputValue) ||
            a.buyerName.toLowerCase().includes(inputValue) ||
            a.vatID.toLowerCase().includes(inputValue),
        );
      }
      options = options.map((option) => this.renderBillingInfo(option));
      resolve(options);
    });
  };

  renderBillingInfoSelector = () => {
    const { order } = this.props;
    const { billingInfos, duplication } = this.state;
    return (
      <div className="my-3">
        <h3 className="p-0 m-0 pb-3">Dane do faktury</h3>
        <AsyncSelector
          title="Dane do faktury"
          name="billingInfoId"
          placeholder="Szukaj..."
          allowNull={true}
          allowCreate={true}
          options={[...(order.billingInfo ? [order.billingInfo] : []), ...billingInfos].map(this.renderBillingInfo)}
          value={duplication.billingInfoId}
          onChange={this.changeDuplicationData}
          onSearch={this.filterBillingInfos}
        />
      </div>
    )
  }

  renderContent = () => {
    const { order } = this.props;
    const { shippingMethods, duplication } = this.state;

    const shippingMethod = duplication.shippingMethodId ? shippingMethods.find((m) => m.id === duplication.shippingMethodId) : null;

    return (
      <Form>
        <Row>
          <Col md={6}>
            <h3 className="p-0 m-0 pb-3">Opcje duplikowania</h3>
            <InputGroup className="pl-5">
              <Input
                type="checkbox"
                id={`options-realization`}
                name={`options[realization]`}
                value={true}
                checked={duplication?.realization ?? true}
                onChange={(e) => this.setState({ duplication: { ...duplication, realization: e.target.checked } })}
                defaultChecked
                readOnly
              />
              <Label for={`options-realization`}>Realizuj zamówienie - nadaj odpowiedni status (odznaczenie tej opcji pozostawi zamówienie w koszyku)</Label>
            </InputGroup>
            {this.renderUserSelector()}
            {this.renderShippingMethodSelector()}
            {shippingMethod && !shippingMethod.isShippingAddressRequired && this.renderCollectPointSelector()}
            {shippingMethod && shippingMethod.isShippingAddressRequired && this.renderShippingAddressSelector()}
            {shippingMethod && shippingMethod.isShippingAddressRequired && this.renderSenderAddressSelector()}
            {this.renderPaymentMethodSelector()}
            {this.renderBillingInfoSelector()}
          </Col>
          <Col md={6}>
            <h3 className="p-0 m-0 pb-3">Wybierz elementy do zduplikowania</h3>
            {order?.products?.map(this.renderProduct)}
          </Col>
        </Row>
      </Form>
    );
  };

  render = () => {
    const { isOpen, title } = this.props;

    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
          toggle={this.toggle}
          size="xl"
        >
          <ModalHeader>
            <CardTitle>{title}</CardTitle>
          </ModalHeader>
          <ModalBody className="py-0">{this.renderContent()}</ModalBody>
          <ModalFooter>
            {this.renderCancelButton()}
            {this.renderSubmitButton()}
          </ModalFooter>
        </Modal>
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    loading: state.orders.loading,
    auth: state.auth.user,
    session: state.session,
    products: state.products.list,
    shippingMethods: state.shippingMethods.list,
    paymentMethods: state.paymentMethods.list,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    listShippingMethods: () => dispatch(shippingMethodsActions.list(0, 1000)),
    listPaymentMethods: () => dispatch(paymentMethodsActions.list(0, 1000)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DuplicationOrderModal),
);
