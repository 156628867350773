import React from 'react';

export default function OrderSource({ source }) {
    switch (source) {
        case "ClientCart":
            return <i className="fas fa-shopping-cart"></i>;
        case "ClientQuote":
            return <i className="fas fa-table"></i>;
        case "ManagementCart":
        case "ManagementQuote":
            return <i className="fas fa-wrench"></i>;
        case "Duplicate":
            return <i className="fas fa-copy"></i>;
        default:
            return <i className="fas fa-cog"></i>;
    }
}