import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as orderStatusesActions from "stores/orders/orderStatuses/actions";
import * as ordersActions from "stores/orders/orders/actions";

import Dropzone from "dropzone";
import { Button, Container, Row, Col } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import OrderSummary from "./cards/OrderSummary";
import OrderShipping from "./cards/OrderShipping";
import OrderFiles from "./cards/OrderFiles";
import OrderAccountancy from "./cards/OrderAccountancy";
import OrderProduct from "./cards/OrderProduct";
import Tabs from "components/Tabs/Tabs";
import AddProductModal from "./cards/AddProductModal";
import apiDriver from "stores/api.driver";
import config from "config/global";
import ReactToPrint from "react-to-print";
import { OrderPrint } from "./OrderPrint";
import localizer from "stores/localizer";
import DuplicationOrderModal from "./cards/DuplicationOrderModal";

Dropzone.autoDiscover = false;

class Order extends React.Component {
  printRef = null;

  constructor(props) {
    super(props);
    this.state = {
      statuses: [],
      loading: false,
      order: null,
      auth: null,
      action: "",
      session: null,
      methods: [],
      currentCulture: this.props.session.culture,
      provisionSelectedCountry: "",
      availabilitySelectedCountry: "",
      currentTab: "summary",
      duplicationModalOpen: false,
    };
  }

  setCurrentTab = (id) => this.setState({ ...this.state, currentTab: id });

  onLoadProductToPrint = (response, resolve) => {
    const product = response.response;
    const productsToPrint = this.state.productsToPrint || [];
    this.setState({ productsToPrint: [...productsToPrint, product] });
    setTimeout(resolve, 100);
  };

  setPrintRef = (ref) => {
    this.printRef = ref;
  };

  getPrintRef = () => {
    return this.printRef;
  };

  printTrigger = () => {
    return (
      <Button color="primary">
        <i className="fa fa-print"></i>
      </Button>
    );
  };

  beforePrintContent = () => {
    this.setState({ isLoading: true });

    const call = (id, resolve) =>
      apiDriver
        .get(`${config.api.orders}pl/OrderProducts/${id}?WithFiles=True`)
        .subscribe((response) => this.onLoadProductToPrint(response, resolve));
    const promises = this.state.order?.products?.map(
      (p) => new Promise((resolve) => call(p.id, resolve)),
    );
    return Promise.all(promises);
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  toggleAddProductModal = (id) =>
    id
      ? this.setState({ [id]: !this.state[id] })
      : this.setState({ addProductModalOpen: !this.state.addProductModalOpen });
  onCreateProduct = (product) => {
    setTimeout(() => this.loadOrderProduct(product.id), 2500);
    this.notify(
      "Dodano produkt",
      `Pomyślnie dodano produkt ${product.product
        ? localizer(product.product)?.currentTranslation?.title
        : "niestandardowy"
      } do zamówienia`,
      "success",
    );
  };

  toggleDuplicationModal = () => {
    this.setState({ duplicationModalOpen: !this.state.duplicationModalOpen });
  };

  onDeleteProduct = (id) => {
    const { order } = this.state;
    const product = [...order.products].find((p) => p.id === id);
    this.setState({
      order: {
        ...order,
        products: [...order.products].filter((p) => p.id !== id),
      },
    });
    this.setCurrentTab("summary");
    this.notify(
      "Usunięto produkt",
      `Pomyślnie usunięto produkt ${product.product
        ? localizer(product.product)?.currentTranslation?.title
        : "niestandardowy"
      } z zamówienia`,
      "success",
    );
  };

  notify = (title, content, type = "default") => {
    let options = {
      place: "br",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{content}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  parseValue = (target) => {
    const { name, value, type, checked } = target;
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = !isNaN(parsedValue) ? Number(parsedValue) : undefined;
        break;
      case "checkbox":
        parsedValue = checked;
        break;
      case "datetime":
        parsedValue = value.format();
        break;
      case "select-one":
        parsedValue = !isNaN(Number(parsedValue)) ? Number(parsedValue) : parsedValue;
        break;
      default:
        break;
    }
    return { name: name, value: parsedValue };
  };

  getDotNotationValue = (entity, notation) =>
    notation.split(".").reduce((r, k) => r?.[k], entity);

  changeDotNotationValue = (entity, notation, value) => {
    if (typeof notation === "string") {
      return this.changeDotNotationValue(entity, notation.split("."), value);
    }

    if (notation.length === 1) {
      return { ...entity, [notation[0]]: value };
    } else {
      return {
        ...entity,
        [notation[0]]: this.changeDotNotationValue(
          entity[notation[0]],
          notation.slice(1),
          value,
        ),
      };
    }
  };

  changeOrderDataEx = (name, value) => {
    let order = { ...this.state.order, [name]: value };
    this.setState({ order });
  };

  changeOrderData = (event) => {
    const value = this.parseValue(event.target);
    let order = { ...this.state.order, [value.name]: value.value };
    this.setState({ order });
  };

  prepareAddressData = (order, id, data) => {
    switch (order[id]) {
      case "_":
        delete order[id];
        break;
      case "":
        order[id] = null;
        delete order[data];
        break;
      default:
        delete order[data];
        break;
    }
  };

  correctToNull = (order, key) => {
    if (order[key] === "" || !order[key]) {
      order[key] = null;
    }
  };

  submitForm = (event) => {
    const {
      priceSum,
      paymentMethod,
      shippingMethod,
      products,
      shippings,
      payments,
      id,
      ...order
    } = { ...this.state.order };

    if (order.userId) {
      this.prepareAddressData(order, "shippingAddressId", "shippingAddress");
      this.prepareAddressData(order, "billingInfoId", "billingInfo");
      this.prepareAddressData(order, "senderAddressId", "senderAddress");
      this.prepareAddressData(order, "collectPointId", "collectPoint");
      this.correctToNull(order, "paymentMethodId");
      this.correctToNull(order, "shippingMethodId");
    } else {
      if (
        !order.shippingAddress?.line1 ||
        !order.shippingAddress?.postalCode ||
        !order.shippingAddress?.city
      ) {
        delete order.shippingAddress;
      }
      if (
        !order.billingInfo?.line1 ||
        !order.billingInfo?.postalCode ||
        !order.billingInfo?.city
      ) {
        delete order.billingInfo;
      }
    }

    if (this.state.order.id) {
      this.setState({ action: "update" });
      this.props.update({ ...order, id: this.state.order.id });
    } else {
      this.setState({ action: "create" });
      this.props.create(order);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickRemove = () => {
    const { order } = this.state;
    this.props.remove(order.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.order || this.props.loading;

    return (
      <Button color="danger" disabled={loading} onClick={this.onClickRemove}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  notifyAboutUpdates = () => {
    const { order } = this.state;
    apiDriver
      .get(`${config.api.orders}pl/Orders/${order.id}/Notify`)
      .subscribe();
  };

  renderButtons = () => {
    const { currentTab, order } = this.state;
    const loading = !this.state.order || this.props.loading;

    if (currentTab === "summary") {
      return (
        <div className="text-right">
          <ReactToPrint
            documentTitle={order?.number || "Draft"}
            trigger={this.printTrigger}
            content={this.getPrintRef}
            onBeforeGetContent={this.beforePrintContent}
            copyStyles={false}
          />
          {order?.id ? (
            <Button
              color="warning"
              onClick={(e) => this.notifyAboutUpdates()}
              disabled={loading}
            >
              <i className="fas fa-bell"></i>
            </Button>
          ) : (
            <React.Fragment />
          )}
          <Button color="primary" onClick={this.submitForm} disabled={loading}>
            {order?.id
              ? `Zapisz ${order?.ordered ? "zamówienie" : "koszyk"}`
              : `Utwórz ${order?.ordered ? "zamówienie" : "koszyk"}`}
          </Button>
          {order?.id ? (
            <>
              <Button
                color="primary"
                onClick={(e) => this.toggleDuplicationModal()}
                disabled={loading}
              >
                Duplikuj
              </Button>
              <Button
                color="success"
                onClick={(e) => this.toggleAddProductModal()}
                disabled={loading}
              >
                Dodaj produkt
              </Button>
            </>
          ) : (
            <React.Fragment />
          )}
          {order?.id ? (
            <Button
              color={order?.ordered ? "success" : "primary"}
              onClick={(e) =>
                this.changeOrderDataEx("ordered", new Date().toISOString())
              }
              disabled={!(
                (order?.userId || (order?.email && order?.phone)) &&
                order?.products?.length > 0 &&
                order?.shippingMethod && (order?.shippingMethod.isShippingAddressRequired ? order.shippingAddressId !== null : order.collectPointId !== null) &&
                order?.paymentMethod && (order?.paymentMethod.isRequiredToProcessOrder === order.shippingMethod.isPaymentRequiredToProcessOrder)
              )}
            >
              <i
                className={
                  order?.ordered
                    ? "fas fa-check-circle"
                    : "fas fa-shopping-cart"
                }
              ></i>{" "}
              &nbsp;
              {order?.ordered ? "Zamówiono" : "Zamów"}
            </Button>
          ) : (
            <React.Fragment />
          )}
          {this.renderDeleteButton()}
        </div>
      );
    } else {
      return (
        <div className="text-right">
          {order?.id ? (
            <Button
              color="warning"
              onClick={(e) => this.notifyAboutUpdates()}
              disabled={loading}
            >
              <i className="fas fa-bell"></i>
            </Button>
          ) : (
            <React.Fragment />
          )}
          {order?.id ? (
            <Button
              color="success"
              onClick={(e) => this.toggleAddProductModal()}
              disabled={loading}
            >
              Dodaj produkt
            </Button>
          ) : (
            <React.Fragment />
          )}
          {this.renderDeleteButton()}
        </div>
      );
    }
  };

  render = () => {
    const loading = !this.state.order || this.props.loading;

    const {
      order,
      action,
      currentTab,
      addProductModalOpen,
      duplicationModalOpen,
      statuses,
      productsToPrint,
    } = this.state;

    if ((action === "create" || action === "remove") && loading === false) {
      return (
        <Redirect exact from="/" to={`/admin/orders/orders/${order.id}`} />
      );
    }
    if (action === "remove" && loading === false) {
      return <Redirect to="/admin/orders/orders/" />;
    }

    let productsTabs = { id: "products", items: [] };
    let productsTabsContent = [];
    if (order !== null && order.products) {
      order.products.forEach((orderProduct) => {
        productsTabs.items.push({
          id: "product-" + orderProduct.id,
          title: `${orderProduct.number || ""} ${localizer(orderProduct?.product)?.currentTranslation?.title ||
            orderProduct.title ||
            "Niestandardowy"
            } - ${localizer(orderProduct?.status)?.currentTranslation?.title || "Konfiguracja"
            }`,
        });
        if (currentTab === "product-" + orderProduct.id) {
          productsTabsContent.push();
        }
      });
    }
    const defaultTabs = [
      { id: "summary", title: "Podsumowanie" },
      { id: "stream", title: "Aktywność" },
      { id: "shipping", title: "Dostawa / odbiór" },
      { id: "accountancy", title: "Księgowość" },
      { id: "files", title: "Pliki" },
    ];
    const pTabs = order?.id ? [productsTabs] : [];
    const tabs = [...defaultTabs, ...pTabs];

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <SimpleHeader
          name={order?.number || order?.id || "Draft"}
          link="/admin/orders/orders"
          parentName="Zamówienia"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="12" className="mb-3">
              <Tabs
                tabs={tabs}
                currentTab={currentTab}
                setCurrentTab={this.setCurrentTab}
              />
            </Col>
            <Col lg="12">
              {currentTab === "summary" && (
                <OrderSummary
                  notify={this.notify}
                  loading={loading}
                  order={order}
                  changeOrderDataEx={this.changeOrderDataEx}
                  parseValue={this.parseValue}
                  loadingOverlay={this.loadingOverlay}
                  statuses={statuses}
                />
              )}
              {currentTab === "shipping" && (
                <OrderShipping
                  notify={this.notify}
                  loading={loading}
                  order={order}
                  changeOrderDataEx={this.changeOrderDataEx}
                  parseValue={this.parseValue}
                  loadingOverlay={this.loadingOverlay}
                />
              )}
              {currentTab === "accountancy" && (
                <OrderAccountancy
                  notify={this.notify}
                  loading={loading}
                  order={order}
                  changeOrderDataEx={this.changeOrderDataEx}
                  parseValue={this.parseValue}
                  loadingOverlay={this.loadingOverlay}
                />
              )}
              {currentTab === "files" && (
                <OrderFiles
                  notify={this.notify}
                  loading={loading}
                  order={order}
                  changeOrderDataEx={this.changeOrderDataEx}
                  parseValue={this.parseValue}
                  loadingOverlay={this.loadingOverlay}
                  loadOrderProduct={this.loadOrderProduct}
                />
              )}
              {order &&
                order.products &&
                order.products.map(
                  (orderProduct) =>
                    currentTab === "product-" + orderProduct.id && (
                      <OrderProduct
                        key={orderProduct.id}
                        notify={this.notify}
                        loading={loading}
                        order={order}
                        orderProductId={orderProduct.id}
                        changeOrderDataEx={this.changeOrderDataEx}
                        parseValue={this.parseValue}
                        loadingOverlay={this.loadingOverlay}
                        changeDotNotationValue={this.changeDotNotationValue}
                        onDelete={this.onDeleteProduct}
                        statuses={statuses}
                      />
                    ),
                )}
            </Col>
          </Row>
          {order && <AddProductModal
            order={order}
            isOpen={addProductModalOpen}
            toggle={(e) => this.toggleAddProductModal()}
            onCreate={this.onCreateProduct}
            title="Dodaj produkt"
          />}
          {order && <DuplicationOrderModal
            order={order}
            isOpen={duplicationModalOpen}
            toggle={(e) => this.toggleDuplicationModal()}
            onCreate={this.onCreateProduct}
            title={`Duplikuj zamówienie ${order?.number}`}
          />}
        </Container>
        <OrderPrint
          order={order}
          products={productsToPrint}
          ref={this.setPrintRef}
        />
      </>
    );
  };

  loadOrderProduct = (orderProductId) => {
    const { order } = this.state;
    apiDriver
      .get(`${config.api.orders}pl/OrderProducts/${orderProductId}`)
      .subscribe({
        next: (r) => this.setState({
          order: {
            ...order,
            products:
              order
                .products
                .some(p => p.id === orderProductId) ?
                [...order.products.map(p => p.id === orderProductId ? { ...p, ...r.response } : { ...p })] :
                [...order.products, r.response]
          }
        })
      });
  };

  clearState = (state = {}) => {
    const defaultState = {
      order: {
        id: null,
        number: null,
        seriesId: null,
        statusId: null,
        culture: 'pl',

        userId: null,
        guestId: null,
        creatorId: null,
        assigneeId: null,

        paymentMethodId: null,
        shippingMethodId: null,

        billingInfoId: null,
        shippingAddressId: null,
        senderAddressId: null,
        collectPointId: null,

        couponId: null,

        created: null,
        ordered: null,
        closed: null,
        updated: null,
        deleted: null,

        guestAccessToken: null,

        series: null,
        status: null,

        user: null,
        guest: null,
        creator: null,
        assignee: null,

        paymentMethod: null,
        shippingMethod: null,

        billingInfo: null,
        shippingAddress: null,
        senderAddress: null,
        collectPoint: null,

        coupon: null,

        products: [],
        notes: [],
        payments: [],
        shippings: [],

        priceSum: null,
      },
      loading: false,
      addProductModalOpen: false,
    };
    this.setState({ ...defaultState, ...state });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.clearState();
    }
    this.props.listStatuses();
  };

  componentDidUpdate(prevProps) {
    const id = this.props.match.params.id;
    if (prevProps.match.params.id !== id && id !== "null" && id !== null) {
      this.clearState({ loading: true, action: "" });
      this.props.read(id);
      return;
    }
    /*if (id !== 'create' && this.state.order?.id !== id) {
      this.props.read(id);
      return;
    }*/

    if (prevProps.statuses !== this.props.statuses) {
      this.setState({
        statuses: this.props.statuses,
      });
    }
    if (prevProps.order !== this.props.order) {
      this.setState({
        order: this.props.order,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
  }

  componentDidCatch() { }

  componentWillUnmount() { }
}

function mapStateToProps(state) {
  return {
    order: state.orders.current,
    loading: state.orders.loading,
    auth: state.auth.user,
    statuses: state.orderStatuses.list,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(ordersActions.read(id)),
    remove: (id) => dispatch(ordersActions.remove(id)),
    update: (order) => dispatch(ordersActions.update(order)),
    create: (order) => dispatch(ordersActions.create(order)),
    listStatuses: () => dispatch(orderStatusesActions.list(0, 1000)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));
