import React from "react";

import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import ReactDatetime from "react-datetime";

import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import * as invoicesActions from "stores/invoices/invoices/actions";

import Dropzone from "dropzone";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Table,
  Spinner,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { API_URL as SELLERS_API_URL } from "stores/invoices/sellersData/epics";
import { API_URL as BUYERS_API_URL } from "stores/invoices/buyersData/epics";
import apiDriver from "stores/api.driver";
import AsyncSelector from "views/pages/orders/orders/cards/AsyncSelector";

import ReactBSAlert from "react-bootstrap-sweetalert";
import config from "config/global";
import NumericInput from "components/Form/NumericInput";
import CountrySelector from "views/pages/orders/orders/cards/CountrySelector";

Dropzone.autoDiscover = false;

export const InvoicePaymentMethods = [
  { id: 1, label: "Przelew" },
  { id: 2, label: "Gotowka" },
  { id: 3, label: "Karta platnicza" },
  { id: 4, label: "Platnosc za pobraniem" },
  { id: 5, label: "Przedplata" },
  { id: 6, label: "Kompensata" },
  { id: 7, label: "Barter" },
  { id: 8, label: "Platnosc online" },
  { id: 9, label: "Inna" },
];

export const InvoiceTypes = [
  { id: 1, label: "Paragon" },
  { id: 2, label: "Faktura VAT" },
  { id: 3, label: "Faktura Proforma" },
  { id: 4, label: "WDT" },
  { id: 5, label: "Faktura korygująca" },
];

export const InvoiceCurrencies = [
  { id: "PLN", label: "Polski zloty" },
  { id: "EUR", label: "Euro" },
  { id: "USD", label: "Dolar amerykanski" },
];

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      invoice: null,
      auth: null,
      action: "",
      session: null,
      methods: [],
      currentCulture: this.props.session.culture,
      provisionSelectedCountry: "",
      availabilitySelectedCountry: "",
      alert: "",
      sellersData: [],
      buyersData: [],
      exchangeRate: 1.0,
      isCorrectionDropdownOpen: false,
      isRetypeDropdownOpen: false,
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id !== "create") {
      this.props.read(id);
    } else {
      this.setState({
        invoice: {
          type: null,
          seriesId: null,

          number: null,
          currency: InvoiceCurrencies[0].id,

          sellerDataId: null,
          buyerDataId: null,

          paymentMethod: null,

          note: null,

          created: null,
          updated: null,
          deleted: null,
          realized: new Date().toISOString(),
          issued: new Date().toISOString(),
          paid: null,
          paymentDuration: null,
          paymentTerm: null,

          series: null,
          sellerData: null,
          buyerData: null,

          positions: [],
          payments: []
        },
        loading: false,
        isDownloadLoading: false,
        isCreationLoading: false,
        redirectTo: "",
      });
    }
  };

  hideAlert = () => {
    this.setState({ alert: "" });
  };

  alert = (title, content, callback) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={title}
          onConfirm={() => {
            if (callback) {
              callback();
            }
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          btnSize=""
        >
          {content}
        </ReactBSAlert>
      ),
    });
  };

  changeCulture = (event) => {
    let value = event.target.value;
    let currentCulture = value;
    this.setState({ currentCulture });
  };

  parseValue = (target) => {
    const { name, value, checked, type } = target;
    let parsedValue = value;
    switch (type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      case "checkbox":
        parsedValue = checked;
        break;
      case "datetime":
        parsedValue = value.format();
        break;
      case "select-one":
        let asNumber = Number(parsedValue);
        parsedValue = isNaN(asNumber) ? parsedValue : asNumber;
        break;
      default:
        break;
    }
    return { name: name, value: parsedValue };
  };

  changeInvoiceDate = (name, value) => {
    const valueSet = this.parseValue({
      name: name,
      value: value,
      checked: false,
      type: "datetime",
    });
    let invoice = { ...this.state.invoice, [valueSet.name]: valueSet.value };
    this.setState({ invoice });
  };

  deleteInvoiceArrayItem = (arrayName, index) => {
    const invoice = { ...this.state.invoice };
    this.setState({
      invoice: {
        ...invoice,
        [arrayName]: [...invoice[arrayName]].filter((v, i) => i !== index),
      },
    });
  };

  addInvoiceArrayItem = (arrayName) => {
    const invoice = { ...this.state.invoice };

    this.setState({
      invoice: { ...invoice, [arrayName]: [...invoice[arrayName], {}] },
    });
  };

  changeInvoiceArrayItemData = (event) => {
    const invoice = { ...this.state.invoice };

    const tmp = event.target.name.split(".");
    const arrayName = tmp[0];
    const index = Number(tmp[1]);

    const target = {
      name: tmp[2],
      value: event.target.value,
      type: event.target.type,
    };
    const value = this.parseValue(target);

    let array = invoice[arrayName];
    array[index] = { ...array[index], [value.name]: value.value };

    this.setState({ invoice: { ...invoice, [arrayName]: array } });
  };

  changeBuyerData = (event) => {
    const value = this.parseValue(event.target);
    let buyerData = { ...this.state.invoice.buyerData };
    buyerData = { ...buyerData, [value.name]: value.value };
    this.setState({ invoice: { ...this.state.invoice, buyerData: buyerData } });
  };

  changeSellerData = (event) => {
    const value = this.parseValue(event.target);
    let sellerData = { ...this.state.invoice.sellerData };
    sellerData = { ...sellerData, [value.name]: value.value };
    this.setState({
      invoice: { ...this.state.invoice, sellerData: sellerData },
    });
  };

  changeInvoiceData = (event) => {
    const value = this.parseValue(event.target);
    let invoice = { ...this.state.invoice };
    invoice = { ...invoice, [value.name]: value.value };
    this.setState({ invoice });
  };

  getViewModel = () => {
    const { sellerData, buyerData, series, ...invoice } = this.state.invoice;

    if (!invoice.type) {
      this.alert("Wybierz rodzaj", "Wybierz rodzaj dokumentu sprzedazy");
      return;
    }
    if (!invoice.paymentMethod) {
      this.alert(
        "Wybierz metode platnosci",
        "Wybierz metode platnosci dokumentu sprzedazy",
      );
      return;
    }
    if (!invoice.sellerDataId) {
      this.alert(
        "Wybierz dane sprzedawcy",
        "Wybierz dane sprzedawcy dokumentu sprzedazy",
      );
      return;
    }
    if (!invoice.buyerDataId) {
      this.alert(
        "Wybierz dane nabywcy",
        "Wybierz dane nabywcy dokumentu sprzedazy",
      );
      return;
    }

    if (invoice.sellerDataId === "_") {
      invoice.sellerData = this.state.invoice.sellerData;
      invoice.sellerDataId = null;
    }
    if (invoice.buyerDataId === "_") {
      invoice.buyerData = this.state.invoice.buyerData;
      invoice.buyerDataId = null;
    }
    if (!invoice.positions || invoice.positions.length < 1) {
      this.alert("Brak pozycji", "Uzupelnij fakture o pozycje");
      return;
    }

    return invoice;
  };

  confirmAndSubmitForm = (event) => {
    let invoice = this.getViewModel();
    invoice.confirmed = true;

    this.sendForm(invoice);
  };

  submitForm = (event) => {
    const invoice = this.getViewModel();

    if (invoice.confirmed) {
      invoice.confirmed = false;
      this.alert(
        "Zatwierdzona faktura",
        "Probujesz zapisac zatwierdzona fakture - czy jestes pewien, ze chcesz zatwierdzic wprowadzone zmiany?",
        (e) => this.sendForm(invoice),
      );
      return;
    }
    this.sendForm(invoice);
  };

  sendForm = (invoice) => {
    if (invoice.id) {
      this.setState({ action: "update" });
      this.props.update(invoice);
    } else {
      this.setState({ action: "create" });
      this.props.create(invoice);
    }
  };

  loadingOverlay = () => {
    return (
      <div
        className="position-absolute h-100 w-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 999,
          verticalAlign: "middle",
        }}
      >
        <div className="d-table h-100 w-100">
          <div className="text-center d-table-cell h-100 w-100 align-middle">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onClickRemove = () => {
    const { invoice } = this.state;
    this.props.remove(invoice.id);
    this.setState({ action: "remove" });
  };

  renderDeleteButton = () => {
    const loading = !this.state.invoice || this.props.loading;

    const { invoice } = this.state;
    const { auth } = this.props;

    if (!invoice || invoice.id === auth.id || !invoice.id) {
      return <></>;
    }

    return (
      <Button color="danger" onClick={this.onClickRemove} disabled={loading}>
        <i className="fas fa-times" />
      </Button>
    );
  };

  download = () => {
    const loading = !this.state.invoice || this.props.loading;
    const { invoice } = this.state;

    if (loading) {
      return false;
    }
    if (!invoice.confirmed) {
      return false;
    }

    apiDriver
      .download(
        `${invoice.number}.pdf`,
        `${config.api.accountancy}pl/Invoices/${invoice.id}/Download`,
      )
      .subscribe((data) => {
        this.setState({ isDownloadLoading: false });
      });
    this.setState({ isDownloadLoading: true });
  };

  createRetyped = (type) => {
    const loading = !this.state.invoice || this.props.loading;
    const { invoice } = this.state;

    if (loading) {
      return false;
    }
    if (invoice.type !== 3) {
      return false;
    }

    apiDriver
      .put(
        `${config.api.accountancy}pl/Invoices/${invoice.id}/DuplicateRetyped/${type}`,
      )
      .pipe(
        map((response) => response.response),
        catchError((error) => of(error)),
      )
      .subscribe((data) => {
        this.setState({
          isCreationLoading: false,
          action: "redirect",
          redirectTo: `/admin/accountancy/invoices/${data.id}`,
        });
      });
    this.setState({ isCreationLoading: true });
  };

  createCorrection = () => {
    const loading = !this.state.invoice || this.props.loading;
    const { invoice } = this.state;

    if (loading) {
      return false;
    }
    if (!invoice.confirmed) {
      return false;
    }

    apiDriver
      .put(
        `${config.api.accountancy}pl/Invoices/${invoice.id}/Correction`,
        invoice,
      )
      .pipe(
        map((response) => response.response),
        catchError((error) => of(error)),
      )
      .subscribe((data) => {
        this.setState({
          isCreationLoading: false,
          action: "redirect",
          redirectTo: `/admin/accountancy/invoices/${data.id}`,
        });
      });
    this.setState({ isCreationLoading: true });
  };

  createZeroCorrection = () => {
    const loading = !this.state.invoice || this.props.loading;
    const { invoice } = this.state;

    if (loading) {
      return false;
    }
    if (!invoice.confirmed) {
      return false;
    }

    apiDriver
      .put(`${config.api.accountancy}pl/Invoices/${invoice.id}/ZeroCorrection`)
      .pipe(
        map((response) => response.response),
        catchError((error) => of(error)),
      )
      .subscribe((data) => {
        this.setState({
          isCreationLoading: false,
          action: "redirect",
          redirectTo: `/admin/orders/invoices/${data.id}`,
        });
      });
    this.setState({ isCreationLoading: true });
  };

  renderButtons = () => {
    const loading = !this.state.invoice || this.props.loading;
    const {
      invoice,
      isCorrectionDropdownOpen,
      isRetypeDropdownOpen,
      isDownloadLoading,
      isCreationLoading,
    } = this.state;

    const toggleCorrectionDropdown = () => {
      this.setState({
        isCorrectionDropdownOpen: !isCorrectionDropdownOpen,
      });
    };

    const toggleRetypeDropdown = () => {
      this.setState({
        isRetypeDropdownOpen: !isRetypeDropdownOpen,
      });
    };

    if (loading) {
      return <Spinner />;
    }

    if (invoice.confirmed) {
      return (
        <div className="text-right">
          <Button
            color="success"
            onClick={this.download}
            disabled={loading || isDownloadLoading}
          >
            <i className="fas fa-download" />{" "}
            {isDownloadLoading ? (
              <i className="fas fa-circle-notch fa-spin" />
            ) : (
              <React.Fragment />
            )}
          </Button>
          {invoice.type === 3 ? (
            <ButtonDropdown
              isOpen={isRetypeDropdownOpen}
              toggle={toggleRetypeDropdown}
            >
              <DropdownToggle
                disabled={loading || isCreationLoading}
                color="primary"
                className="mr-2"
                caret
              >
                Duplikuj{" "}
                {isCreationLoading ? (
                  <i className="fas fa-circle-notch fa-spin" />
                ) : (
                  <React.Fragment />
                )}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Wybierz nowy rodzaj</DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  disabled={loading || isCreationLoading}
                  onClick={(e) => this.createRetyped(1)}
                >
                  Paragon
                </DropdownItem>
                <DropdownItem
                  disabled={loading || isCreationLoading}
                  onClick={(e) => this.createRetyped(2)}
                >
                  Faktura VAT
                </DropdownItem>
                <DropdownItem
                  disabled={loading || isCreationLoading}
                  onClick={(e) => this.createRetyped(3)}
                >
                  WDT
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          ) : (
            <React.Fragment />
          )}
          {invoice.type !== 5 ? (
            <ButtonDropdown
              isOpen={isCorrectionDropdownOpen}
              toggle={toggleCorrectionDropdown}
            >
              <DropdownToggle
                disabled={loading || isCreationLoading}
                color="primary"
                className="mr-2"
                caret
              >
                Korekta{" "}
                {isCreationLoading ? (
                  <i className="fas fa-circle-notch fa-spin" />
                ) : (
                  <React.Fragment />
                )}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Rodzaj korekty</DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  disabled={loading || isCreationLoading}
                  onClick={this.createCorrection}
                >
                  Biezące zmiany
                </DropdownItem>
                <DropdownItem
                  disabled={loading || isCreationLoading}
                  onClick={this.createZeroCorrection}
                >
                  Zerująca
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          ) : (
            <React.Fragment />
          )}
          <Button
            color="secondary"
            onClick={this.submitForm}
            disabled={loading}
          >
            <i className="fas fa-exclamation-circle text-warning"></i> &nbsp;
            Cofnij zatwierdzenie i zapisz
          </Button>
          {this.renderDeleteButton()}
        </div>
      );
    } else {
      return (
        <div className="text-right">
          <Button
            color="primary"
            onClick={this.confirmAndSubmitForm}
            disabled={loading}
          >
            Zapisz i zatwierdz
          </Button>
          <Button
            color="secondary"
            onClick={this.submitForm}
            disabled={loading}
          >
            Zapisz
          </Button>
          {this.renderDeleteButton()}
        </div>
      );
    }
  };

  renderId = () => {
    const { invoice } = this.state;

    if (!invoice || !invoice.id) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Col md="3">Id</Col>
        <Col md="9">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-tag" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Id"
                type="text"
                name="id"
                value={invoice ? invoice.id : ""}
                readOnly={true}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </React.Fragment>
    );
  };

  renderCardSummary = () => {
    const { invoice } = this.state;

    if (!invoice) {
      return <></>;
    }

    let netSum = 0.0;
    let grossSum = 0.0;

    let taxRates = [];

    invoice.positions.forEach((position) => {
      if (position.value && position.amount && position.taxRate) {
        const netPrice = position.value;
        let grossPrice =
          Math.ceil((netPrice + netPrice * position.taxRate) * (100)) / 100;
        const netCost = Math.ceil(netPrice * position.amount * (100 - (position.rebateRate || 0))) / 100;
        const grossCost = Math.ceil(grossPrice * position.amount * (100 - (position.rebateRate || 0))) / 100;
        netSum += netCost;
        grossSum += grossCost;

        if (taxRates.some((t) => t.taxRate === position.taxRate)) {
          taxRates = taxRates.map((t) => {
            if (t.taxRate === position.taxRate) return t;
            return {
              ...t,
              netCost: t.netCost + netCost,
              grossCost: t.grossCost + grossCost,
            };
          });
        } else {
          taxRates.push({
            taxRate: position.taxRate,
            netCost: netCost,
            grossCost: grossCost,
          });
        }
      }
    });

    let grossPaid = 0.0;

    invoice.payments.forEach((payment) => {
      if (payment.value) {
        grossPaid += payment.value;
      }
    });

    const grossUnpaid = grossSum - grossPaid;

    if (!invoice) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podsumowanie</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Netto</th>
                  <th>Brutto</th>
                </tr>
              </thead>
              <tbody>
                {taxRates &&
                  taxRates.map((t) => (
                    <tr key={t.taxRate}>
                      <th>{t.taxRate}%</th>
                      <td>{t.netCost.toFixed(2)}</td>
                      <td>{t.grossCost.toFixed(2)}</td>
                    </tr>
                  ))}
                <tr>
                  <th>Suma pozycji</th>
                  <td>{netSum.toFixed(2)}</td>
                  <td>{grossSum.toFixed(2)}</td>
                </tr>
                <tr>
                  <th>Oplacono</th>
                  <td></td>
                  <td>{grossPaid.toFixed(2)}</td>
                </tr>
                <tr>
                  <th>Pozostalo do zaplaty</th>
                  <td></td>
                  <td>{grossUnpaid.toFixed(2)}</td>
                </tr>
              </tbody>
            </Table>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderPayment = (payment, index) => {
    const { currency } = this.state.invoice;

    return (
      <Row key={index}>
        <Col md="3">
          {payment.id ? (
            <>
              <div>{payment.id}</div>
              <small className="text-muted">{payment.externalId}</small>
            </>
          ) : (
            <small className="text-muted">Draft (brak ID)</small>
          )}
        </Col>
        <Col md="2">
          <FormGroup>
            <Input
              type="select"
              name={`payments.${index}.method`}
              value={payment.method}
              onChange={this.changeInvoiceArrayItemData}
              placeholder="Metoda"
            >
              <option value={null}>Wybierz...</option>
              {InvoicePaymentMethods.map((method) => (
                <option key={method.id} value={method.id}>
                  {method.label}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <InputGroup>
              <NumericInput
                name={`payments.${index}.value`}
                value={payment.value}
                onChange={this.changeInvoiceArrayItemData}
                placeholder="Kwota"
                step={0.01}
              />
              <InputGroupAddon addonType="append">{currency}</InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Input
              type="datetime"
              name={`payments.${index}.created`}
              value={payment.created}
              onChange={this.changeInvoiceArrayItemData}
              placeholder="Data platnosci"
            />
          </FormGroup>
        </Col>
        <Col md="1" className="pt-2 text-right">
          <Button
            color="danger"
            size="sm"
            onClick={(e) => this.deleteInvoiceArrayItem("payments", index)}
          >
            <i className="fas fa-times"></i>
          </Button>
        </Col>
      </Row>
    );
  };

  renderCardPayments = () => {
    const { invoice } = this.state;

    if (!invoice) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <div className="clearfix">
            <div className="float-left">
              <h3 className="mb-0 float-left">Platnosci</h3>
            </div>
            <div className="float-right">
              <Button
                color="success"
                size="sm"
                onClick={(e) => this.addInvoiceArrayItem("payments")}
              >
                <i className="fas fa-plus"></i>
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>{invoice.payments.map(this.renderPayment)}</CardBody>
      </Card>
    );
  };

  renderPosition = (position, index) => {
    const { currency } = this.state.invoice;

    let netCost = 0.0;
    let grossCost = 0.0;

    if (position.value && position.amount && position.taxRate) {
      let netPrice = position.value;
      let grossPrice =
        Math.ceil((netPrice + netPrice * position.taxRate) * 100) / 100;
      netCost = Math.ceil(netPrice * position.amount * (100 - (position.rebateRate || 0))) / 100;
      grossCost = Math.ceil(grossPrice * position.amount * (100 - (position.rebateRate || 0))) / 100;
    }

    const taxRates = [
      { value: 0.00, label: '0%' },
      { value: 0.05, label: '5%' },
      { value: 0.08, label: '8%' },
      { value: 0.23, label: '23%' },
    ]

    return (
      <tr key={index}>
        <td>
          <FormGroup>
            <Input
              type="text"
              name={`positions.${index}.PKWIU`}
              value={position.PKWIU}
              onChange={this.changeInvoiceArrayItemData}
              placeholder="PKWiU"
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="text"
              name={`positions.${index}.title`}
              value={position.title}
              onChange={this.changeInvoiceArrayItemData}
              placeholder="Tytul"
              style={{ width: "350px" }}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup style={{ width: "150px" }}>
            <InputGroup>
              <NumericInput
                name={`positions.${index}.value`}
                value={position.value}
                onChange={this.changeInvoiceArrayItemData}
                placeholder="Cena netto"
                step={0.01}
              />
              <InputGroupAddon addonType="append">{currency}</InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <NumericInput
              name={`positions.${index}.amount`}
              value={position.amount}
              onChange={this.changeInvoiceArrayItemData}
              placeholder="Ilosc"
              step={0.01}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="text"
              name={`positions.${index}.unit`}
              value={position.unit}
              onChange={this.changeInvoiceArrayItemData}
              placeholder="J.m."
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="select"
              name={`positions.${index}.taxRate`}
              value={position.taxRate}
              placeholder="Stawka podatku VAT"
              onChange={this.changeInvoiceArrayItemData}
            >
              {taxRates.map(rate => <option key={rate.value} value={rate.value}>{rate.label}</option>)}
            </Input>
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="number"
              name={`positions.${index}.rebateRate`}
              value={position.rebateRate}
              onChange={this.changeInvoiceArrayItemData}
              placeholder="Rabat"
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="text"
              value={netCost.toFixed(2)}
              placeholder="Koszt netto"
              readOnly={true}
            />
          </FormGroup>
        </td>
        <td>
          <FormGroup>
            <Input
              type="text"
              value={grossCost.toFixed(2)}
              placeholder="Koszt brutto"
              readOnly={true}
            />
          </FormGroup>
        </td>
        <td>
          <Button
            color="danger"
            size="sm"
            onClick={(e) => this.deleteInvoiceArrayItem("positions", index)}
          >
            <i className="fas fa-times"></i>
          </Button>
        </td>
      </tr>
    );
  };

  renderCardPositions = () => {
    const { invoice } = this.state;

    if (!invoice) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <div className="clearfix">
            <div className="float-left">
              <h3 className="mb-0 float-left">Pozycje</h3>
            </div>
            <div className="float-right">
              <Button
                color="success"
                size="sm"
                onClick={(e) => this.addInvoiceArrayItem("positions")}
              >
                <i className="fas fa-plus"></i>
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <Table>
              <thead>
                <tr>
                  <th>PKWiU</th>
                  <th>Tytul</th>
                  <th>Cena netto</th>
                  <th>Ilosc</th>
                  <th>J.M.</th>
                  <th>Stawka podatku VAT</th>
                  <th>Rabat</th>
                  <th>Koszt netto</th>
                  <th>Koszt brutto</th>
                </tr>
              </thead>
              <tbody>{invoice.positions.map(this.renderPosition)}</tbody>
            </Table>
          </Form>
        </CardBody>
      </Card>
    );
  };

  sellerDataLabel = (data) => {
    return (
      <div>
        <div>
          <span className="font-weight-bold">{data?.name}</span>{" "}
          {data?.vatID ? <span>(VAT: {data?.vatID})</span> : <React.Fragment />}
        </div>
        <div className="text-muted">
          <small>
            <div>
              <div className="d-inline-block">
                <i className="fas fa-map mr-3"></i>
              </div>
              <div className="d-inline-block">
                <div>{data.line1}</div>
                {data.line2 ? <div>{data.line2}</div> : <React.Fragment />}
                <div>
                  {data.postalCode} {data.city}
                </div>
              </div>
            </div>
            {data.bankName || data.bankAccountNumber ? (
              <div>
                <i className="fas fa-credit-card mr-3"></i> {data.bankName}{" "}
                {data.bankAccountNumber}
              </div>
            ) : (
              <React.Fragment />
            )}
          </small>
        </div>
      </div>
    );
  };

  onSubmitNip = () => {
    let invoice = { ...this.state.invoice };
    let nip = this.state.invoice.buyerData.vatID;
    if (!nip) {
      console.log('Brak NIPu');
      return;
    }
    if (nip.length !== 10 && nip.length !== 12) {
      console.log('NIP musi mieć 10 lub 12 znaków');
      return;
    }
    if (nip.length === 12) {
      const countryCode = nip.substring(0, 2);
      if (countryCode.toLowerCase() !== 'pl') {
        console.log('Mozna pobrać dane z GUS jedynie o firmach z Polski');
        return;
      }
      nip = nip.substring(2);
    }
    apiDriver
      .get(`${config.api.orders}pl/BillingInfos/Gus/${nip}`)
      .subscribe((response) => {
        if (response.response) {
          let info = { ...response.response };
          info.name = info.buyerName;
          delete info.id;
          delete info.buyerName;
          invoice = { ...invoice, buyerData: info };
          this.setState({ invoice });
        }
      });
  };

  buyerDataLabel = (data) => {
    return (
      <div>
        <div>
          <span className="font-weight-bold">{data?.name}</span>{" "}
          {data?.vatID ? <span>(VAT: {data?.vatID})</span> : <React.Fragment />}
        </div>
        <div className="text-muted">
          <small>
            <div>
              <div className="d-inline-block">
                <i className="fas fa-map mr-3"></i>
              </div>
              <div className="d-inline-block">
                <div>{data.line1}</div>
                {data.line2 ? <div>{data.line2}</div> : <React.Fragment />}
                <div>
                  {data.postalCode} {data.city}
                </div>
              </div>
            </div>
          </small>
        </div>
      </div>
    );
  };

  renderSellerData = (data) => ({
    value: data.id,
    label: this.sellerDataLabel(data),
  });
  renderBuyerData = (data) => ({
    value: data.id,
    label: this.buyerDataLabel(data),
  });

  loadBuyersData = (inputValue) => {
    return apiDriver
      .get(BUYERS_API_URL + "?Take=10&SearchText=" + inputValue)
      .toPromise()
      .then((result) => {
        this.setState({ buyersData: result.response });
        return result.response;
      })
      .then((buyerData) => buyerData.map(this.renderBuyerData));
  };

  loadSellersData = (inputValue) => {
    return apiDriver
      .get(SELLERS_API_URL + "?Take=10&SearchText=" + inputValue)
      .toPromise()
      .then((result) => {
        this.setState({ sellersData: result.response });
        return result.response;
      })
      .then((sellerData) => sellerData.map(this.renderSellerData));
  };

  renderCardSeller = () => {
    const { invoice, sellersData } = this.state;

    if (!invoice) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Sprzedawca</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <FormGroup>
              <AsyncSelector
                title="Sprzedawca"
                name="sellerDataId"
                placeholder="Szukaj..."
                allowNull={true}
                options={(
                  [...sellersData, invoice.sellerData]
                ).filter(e => e).map(this.renderSellerData)}
                value={invoice.sellerDataId}
                onChange={this.changeInvoiceData}
                onSearch={this.loadSellersData}
                allowCreate={true}
              />
            </FormGroup>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    onChange={this.changeSellerData}
                    value={invoice.sellerData?.name}
                    placeholder="Nazwa sprzedawy"
                    readOnly={!["_"].includes(invoice.sellerDataId)}
                    maxLength={128}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Input
                    type="text"
                    name="vatID"
                    onChange={this.changeSellerData}
                    value={invoice.sellerData?.vatID}
                    placeholder="Numer VAT"
                    readOnly={!["_"].includes(invoice.sellerDataId)}
                    maxLength={16}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Input
                    type="text"
                    name="line1"
                    onChange={this.changeSellerData}
                    value={invoice.sellerData?.line1}
                    placeholder="Adres"
                    readOnly={!["_"].includes(invoice.sellerDataId)}
                    maxLength={64}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Input
                    type="text"
                    name="line2"
                    onChange={this.changeSellerData}
                    value={invoice.sellerData?.line2}
                    placeholder="Adres (linia dodatkowa)"
                    readOnly={!["_"].includes(invoice.sellerDataId)}
                    maxLength={64}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Input
                    type="text"
                    name="postalCode"
                    onChange={this.changeSellerData}
                    value={invoice.sellerData?.postalCode}
                    placeholder="Kod pocztowy"
                    readOnly={!["_"].includes(invoice.sellerDataId)}
                    maxLength={16}
                  />
                </FormGroup>
              </Col>
              <Col md="8">
                <FormGroup>
                  <Input
                    type="text"
                    name="city"
                    onChange={this.changeSellerData}
                    value={invoice.sellerData?.city}
                    placeholder="Miejscowosc"
                    readOnly={!["_"].includes(invoice.sellerDataId)}
                    maxLength={64}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Input
                    type="text"
                    name="bankName"
                    onChange={this.changeSellerData}
                    value={invoice.sellerData?.bankName}
                    placeholder="Nazwa banku"
                    readOnly={!["_"].includes(invoice.sellerDataId)}
                    maxLength={64}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <CountrySelector
                    type="text"
                    name="countryCode"
                    onChange={this.changeSellerData}
                    value={invoice.sellerData?.countryCode}
                    placeholder="Kraj"
                    readOnly={!["_"].includes(invoice.sellerDataId)}
                  />
                </FormGroup>
              </Col>
              <Col md="8">
                <FormGroup>
                  <Input
                    type="text"
                    name="bankAccountNumber"
                    onChange={this.changeSellerData}
                    value={
                      invoice.sellerData?.bankAccountNumber
                    }
                    placeholder="Numer konta IBAN"
                    readOnly={!["_"].includes(invoice.sellerDataId)}
                    maxLength={64}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Input
                    type="text"
                    name="notes"
                    onChange={this.changeSellerData}
                    value={invoice.sellerData?.notes}
                    placeholder="Uwagi"
                    readOnly={!["_"].includes(invoice.sellerDataId)}
                    maxLength={256}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardBuyer = () => {
    const { invoice, buyersData } = this.state;

    if (!invoice) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Nabywca</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <FormGroup>
              <AsyncSelector
                title="Nabywca"
                name="buyerDataId"
                placeholder="Szukaj..."
                allowNull={true}
                options={(
                  [...buyersData, invoice.buyerData]
                ).filter(e => e).map(this.renderBuyerData)}
                value={invoice.buyerDataId}
                onChange={this.changeInvoiceData}
                onSearch={this.loadBuyersData}
                allowCreate={true}
              />
            </FormGroup>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    onChange={this.changeBuyerData}
                    value={invoice.buyerData?.name}
                    placeholder="Nazwa nabywcy"
                    readOnly={!["_"].includes(invoice.buyerDataId)}
                    maxLength={128}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      name="vatID"
                      onChange={this.changeBuyerData}
                      value={invoice.buyerData?.vatID}
                      placeholder="Numer VAT"
                      readOnly={!["_"].includes(invoice.buyerDataId)}
                      maxLength={16}
                    />
                    <InputGroupAddon addonType="append">
                      <Button type="button" size="sm">
                        <i className="fas fa-search" onClick={this.onSubmitNip}></i>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Input
                    type="text"
                    name="line1"
                    onChange={this.changeBuyerData}
                    value={invoice.buyerData?.line1}
                    placeholder="Adres"
                    readOnly={!["_"].includes(invoice.buyerDataId)}
                    maxLength={64}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Input
                    type="text"
                    name="line2"
                    onChange={this.changeBuyerData}
                    value={invoice.buyerData?.line2}
                    placeholder="Adres (linia dodatkowa)"
                    readOnly={!["_"].includes(invoice.buyerDataId)}
                    maxLength={64}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Input
                    type="text"
                    name="postalCode"
                    onChange={this.changeBuyerData}
                    value={invoice.buyerData?.postalCode}
                    placeholder="Kod pocztowy"
                    readOnly={!["_"].includes(invoice.buyerDataId)}
                    maxLength={16}
                  />
                </FormGroup>
              </Col>
              <Col md="8">
                <FormGroup>
                  <Input
                    type="text"
                    name="city"
                    onChange={this.changeBuyerData}
                    value={invoice.buyerData?.city}
                    placeholder="Miejscowosc"
                    readOnly={!["_"].includes(invoice.buyerDataId)}
                    maxLength={64}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <CountrySelector
                    type="text"
                    name="countryCode"
                    onChange={this.changeBuyerData}
                    value={invoice.buyerData?.countryCode}
                    placeholder="Kraj"
                    readOnly={!["_"].includes(invoice.buyerDataId)}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Input
                    type="text"
                    name="email"
                    onChange={this.changeBuyerData}
                    value={invoice.buyerData?.email}
                    placeholder="Email"
                    readOnly={!["_"].includes(invoice.buyerDataId)}
                    maxLength={64}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Input
                    type="text"
                    name="notes"
                    onChange={this.changeBuyerData}
                    value={invoice.buyerData?.notes}
                    placeholder="Uwagi"
                    readOnly={!["_"].includes(invoice.buyerDataId)}
                    maxLength={256}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardDates = () => {
    const { invoice } = this.state;

    if (!invoice) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Stemple czasowe</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Data wystawienia</Col>
              <Col md="9">
                <FormGroup>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Data wystawienia",
                      className: "form-control",
                    }}
                    value={new Date(invoice.issued)}
                    timeFormat={false}
                    dateFormat="yyyy-MM-DD"
                    onChange={this.changeInvoiceDate.bind(this, "issued")}
                    name={"end"}
                    className="form-control p-0 m-0 border-0"
                  />
                </FormGroup>
              </Col>
              <Col md="3">Data sprzedazy</Col>
              <Col md="9">
                <FormGroup>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Data sprzedazy",
                      className: "form-control",
                    }}
                    value={new Date(invoice.realized)}
                    timeFormat={false}
                    dateFormat="yyyy-MM-DD"
                    onChange={this.changeInvoiceDate.bind(this, "realized")}
                    name={"end"}
                    className="form-control p-0 m-0 border-0"
                  />
                </FormGroup>
              </Col>
              <Col md="3">Termin platnosci</Col>
              <Col md="4">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-calendar" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumericInput
                      placeholder="Ilosc dni"
                      name="paymentDuration"
                      value={invoice.paymentDuration}
                      onChange={this.changeInvoiceData}
                      step={1}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="5">
                <FormGroup>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Termin platnosci",
                      className: "form-control",
                    }}
                    value={new Date(invoice.paymentTerm)}
                    timeFormat={false}
                    dateFormat="yyyy-MM-DD"
                    onChange={this.changeInvoiceDate.bind(this, "paymentTerm")}
                    name={"end"}
                    className="form-control p-0 m-0 border-0"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderCardData = () => {
    const { invoice, exchangeRate } = this.state;

    if (!invoice) {
      return <></>;
    }

    const loading = this.props.loading;

    return (
      <Card>
        {loading ? this.loadingOverlay() : ""}
        <CardHeader>
          <h3 className="mb-0 float-left">Podstawowe dane</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              {this.renderId()}
              <Col md="3">Numer</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-barcode" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Numer dokumentu"
                      type="text"
                      name="number"
                      value={invoice.number}
                      readOnly={true}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Rodzaj</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-file" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Rodzaj"
                      type="select"
                      name="type"
                      value={invoice.type}
                      onChange={this.changeInvoiceData}
                    >
                      <option value={null}>Wybierz...</option>
                      {InvoiceTypes.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.label}
                        </option>
                      ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Metoda platnosci</Col>
              <Col md="9">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-credit-card" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Metoda platnosci"
                      type="select"
                      name="paymentMethod"
                      value={invoice.paymentMethod}
                      onChange={this.changeInvoiceData}
                      required
                    >
                      <option value={null}>Wybierz...</option>
                      {InvoicePaymentMethods.map((method) => (
                        <option key={method.id} value={method.id}>
                          {method.label}
                        </option>
                      ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="3">Waluta</Col>
              <Col md="5">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-euro-sign" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Waluta"
                      type="select"
                      name="currency"
                      value={invoice.currency}
                      onChange={this.changeInvoiceData}
                    >
                      {InvoiceCurrencies.map((currency) => (
                        <option key={currency.id} value={currency.id}>
                          {currency.label}
                        </option>
                      ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="4" className="pt-2">
                {invoice.currency !== "PLN" ? (
                  <span className="text-muted">
                    1{invoice.currency} = {exchangeRate}PLN
                  </span>
                ) : (
                  <React.Fragment />
                )}
              </Col>
              {invoice.exchangeRate && invoice.exchangeRate !== 1.0000 && (
                <>
                  <Col md="3">Kurs walutowy</Col>
                  <Col md="9">
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            1 {invoice.currency} =
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Waluta"
                          type="number"
                          name="exchangeRate"
                          value={invoice.exchangeRate}
                          readOnly
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            PLN
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const loading = !this.state.invoice || this.props.loading;

    const { invoice, action, alert } = this.state;

    if (action === "redirect") {
      const { redirectTo } = this.state;
      return <Redirect to={redirectTo} />;
    }

    if ((action === "create" || action === "remove") && loading === false) {
      return <Redirect to="/admin/accountancy/invoices/" />;
    }

    return (
      <>
        <SimpleHeader
          name={invoice ? invoice.currentTranslation?.title : ""}
          link="/admin/accountancy/invoices/"
          parentName="Dokumenty księgowe"
        >
          {this.renderButtons()}
        </SimpleHeader>
        <Container className="mt--6" fluid>
          {alert}
          {loading ? this.loadingOverlay() : ""}
          <Row>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardData()}</div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardDates()}</div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardSeller()}</div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">{this.renderCardBuyer()}</div>
            </Col>
            <Col lg="12">
              <div className="card-wrapper">{this.renderCardPositions()}</div>
            </Col>
            <Col lg="12">
              <div className="card-wrapper">{this.renderCardPayments()}</div>
            </Col>
            <Col lg="12">
              <div className="card-wrapper">{this.renderCardSummary()}</div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  fetchExchangeRate = () => {
    const { currency, issued } = this.state.invoice;
    if (currency === "PLN") {
      return;
    }
    apiDriver
      .get(
        config.api.accountancy +
        `pl/ExchangeRates/${currency}/${issued.substring(0, 10)}`,
      )
      .toPromise()
      .then((response) => response.response)
      .then((exchangeRate) => exchangeRate.value)
      .then((rate) => this.setState({ exchangeRate: rate }));
  };

  calculatePaymentTerm = (paymentDuration, issued) => {
    if (!paymentDuration) {
      paymentDuration = this.state.invoice.paymentDuration;
    }
    if (!issued) {
      issued = this.state.invoice.issued;
    }
    let paymentTerm = new Date(issued);
    paymentTerm.setDate(paymentTerm.getDate() + paymentDuration);
    this.setState({
      invoice: {
        ...this.state.invoice,
        paymentDuration: paymentDuration,
        paymentTerm: paymentTerm.toISOString(),
      },
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.invoice !== this.props.invoice) {
      this.setState({
        invoice: this.props.invoice,
        loading: this.props.loading,
        auth: this.props.auth,
      });
    }
    if (!prevState.invoice && this.state.invoice) {
      if (
        this.state.invoice.paymentDuration === null &&
        this.state.invoice.paymentTerm === null
      ) {
        this.calculatePaymentTerm(7, new Date().toISOString());
      }
      if (this.state.invoice.currency !== "PLN") {
        this.fetchExchangeRate();
      }
    }
    if (prevState.invoice && this.state.invoice) {
      if (
        this.state.invoice.paymentDuration !== prevState.invoice.paymentDuration
      ) {
        this.calculatePaymentTerm();
      }
      if (prevState.invoice.sellerDataId !== this.state.invoice.sellerDataId) {
        if (
          this.state.invoice.sellerDataId === "" ||
          this.state.invoice.sellerDataId === "_"
        ) {
          this.setState({ invoice: { ...this.state.invoice, sellerData: {} } });
        } else {
          if (this.state.sellersData && this.state.sellersData.length > 0) {
            this.setState({
              invoice: {
                ...this.state.invoice,
                sellerData: [...this.state.sellersData].find(
                  (d) => d.id === this.state.invoice.sellerDataId,
                ),
              },
            });
          }
        }
      }
      if (prevState.invoice.buyerDataId !== this.state.invoice.buyerDataId) {
        if (
          this.state.invoice.buyerDataId === "" ||
          this.state.invoice.buyerDataId === "_"
        ) {
          this.setState({ invoice: { ...this.state.invoice, buyerData: {} } });
        } else {
          if (this.state.buyersData && this.state.buyersData.length > 0) {
            this.setState({
              invoice: {
                ...this.state.invoice,
                buyerData: [...this.state.buyersData].find(
                  (d) => d.id === this.state.invoice.buyerDataId,
                ),
              },
            });
          }
        }
      }
      if (
        prevState.invoice.currency !== this.state.invoice.currency ||
        prevState.invoice.issued !== this.state.invoice.issued
      ) {
        this.fetchExchangeRate();
      }
    }
  }
}

function mapStateToProps(state) {
  return {
    invoice: state.invoices.current,
    loading: state.invoices.loading,
    auth: state.auth.user,
    session: state.session,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    read: (id) => dispatch(invoicesActions.read(id)),
    remove: (id) => dispatch(invoicesActions.remove(id)),
    update: (invoice) => dispatch(invoicesActions.update(invoice)),
    create: (invoice) => dispatch(invoicesActions.create(invoice)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Invoice),
);
