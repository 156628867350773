import React from "react";

import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from "reactstrap";
import CountrySelector from "./CountrySelector";

import apiDriver from "stores/api.driver";
import config from "config/global";

class BillingInfoForm extends React.Component {
  render() {
    const { id, address, onChange, onSet } = this.props;

    const onSubmitNip = () => {
      let nip = address.vatID;
      if (!nip) {
        console.log('Brak NIPu');
        return;
      }
      if (nip.length !== 10 && nip.length !== 12) {
        console.log('NIP musi mieć 10 lub 12 znaków');
        return;
      }
      if (nip.length === 12) {
        const countryCode = nip.substring(0, 2);
        if (countryCode.toLowerCase() !== 'pl') {
          console.log('Mozna pobrać dane z GUS jedynie o firmach z Polski');
          return;
        }
        nip = nip.substring(2);
      }
      apiDriver
        .get(`${config.api.orders}pl/BillingInfos/Gus/${nip}`)
        .subscribe((response) => {
          if (response.response) {
            let info = { ...response.response, dirty: true };
            delete info.id;
            onSet({ ...address, ...info });
          }
        });
    };

    return (
      <Row>
        <Col md="12">
          <Label inline="false" for={`${id}_buyerName`}>
            <small className="text-uppercase text-muted">Nazwa firmy</small>
          </Label>
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_buyerName`}
                bsSize="sm"
                type="text"
                name="buyerName"
                value={address?.buyerName}
                onChange={onChange}
                maxLength={128}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12">
          <Label inline="false" for={`${id}_vatID`}>
            <small className="text-uppercase text-muted">Numer VAT</small>
          </Label>
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_vatID`}
                bsSize="sm"
                type="text"
                name="vatID"
                value={address?.vatID}
                onChange={onChange}
                maxLength={16}
              />
              <InputGroupAddon addonType="append">
                <Button type="button" size="sm">
                  <i className="fas fa-search" onClick={onSubmitNip}></i>
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12">
          <Label inline="false" for={`${id}_line1`}>
            <small className="text-uppercase text-muted">Adres</small>
          </Label>
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_line1`}
                bsSize="sm"
                type="text"
                name="line1"
                value={address?.line1}
                onChange={onChange}
                maxLength={64}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_line2`}
                bsSize="sm"
                type="text"
                name="line2"
                value={address?.line2}
                onChange={onChange}
                maxLength={64}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="4">
          <Label inline="false" for={`${id}_postalCode`}>
            <small className="text-uppercase text-muted">Kod pocztowy</small>
          </Label>
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_postalCode`}
                bsSize="sm"
                type="text"
                name="postalCode"
                value={address?.postalCode}
                onChange={onChange}
                maxLength={16}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="8">
          <Label inline="false" for={`${id}_city`}>
            <small className="text-uppercase text-muted">Miejscowość</small>
          </Label>
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_city`}
                bsSize="sm"
                type="text"
                name="city"
                value={address?.city}
                onChange={onChange}
                maxLength={64}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12">
          <Label inline="false" for={`${id}_countryCode`}>
            <small className="text-uppercase text-muted">Kraj</small>
          </Label>
          <FormGroup>
            <CountrySelector
              value={address?.countryCode}
              name="countryCode"
              onChange={onChange}
              inputProps={{ size: "sm" }}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <Label inline="false" for={`${id}_email`}>
            <small className="text-uppercase text-muted">Adres e-mail</small>
          </Label>
          <FormGroup>
            <InputGroup>
              <Input
                id={`${id}_email`}
                bsSize="sm"
                type="text"
                name="email"
                value={address?.email}
                onChange={onChange}
                maxLength={64}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default BillingInfoForm;
